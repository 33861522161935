.swal2-title {
  font-family: Helvetica-light, sans-serif !important;
  font-weight: 400;
  color: black;
}

.swal2-confirm {
  padding: 12px 40px;
}

.swal2-success-line-tip,
.swal2-success-line-long,
.swal2-rotate-success-circular-line  {
  background-color: #161348 !important;
  color: #161348!important;
}

.swal2-icon,
.swal2-success,
.swal2-success-ring {
  border: 0.25em solid rgb(51,116,194) !important;
}
